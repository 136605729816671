<template>
  <div>
    <img class="img" src="@/assets/tikkon/tikkon_4/tikkon_4_01.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_4/tikkon_4_02.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_4/tikkon_4_03.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_4/tikkon_4_04.png" alt="">
    <div class="click">
      <img class="img" src="@/assets/tikkon/tikkon_4/tikkon_4_05.png" alt="">
      <div class="btn_list">
        <div class="btn" @click="clickTaba(3)"></div>
        <div class="btn" @click="clickTaba(2)"></div>
        <div class="btn" @click="clickTaba(5)"></div>
        <div class="btn" @click="clickTaba(4)"></div>
        <div class="btn" @click="clickTaba(0)"></div>
        <div class="btn" @click="clickTaba(1)"></div>
      </div>
    </div>
    <div  class="click">
      <div class="tobtn" @click="clickIndex"></div>
      <img class="img" src="@/assets/tikkon/tikkon_4/tikkon_4_06.jpg" alt="">
    </div>
  </div>
</template>

<script>
import axios from '../../http'
import CryptoJS from 'crypto-js'
import wx from "weixin-js-sdk"
export default {
  name: 'App',
  components: {

  },
  methods: {
    clickIndex() {
      this.$router.push('/tikkon');
    },
    clickTaba(index) {
      const url = {
        0: "/tikkon/research",
        1: "/tikkon/technology",
        2: '/tikkon/city',
        3: '/tikkon/service',
        4: '/tikkon/cultural-tourism',
        5: '/tikkon/build',
      };
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push(url[index]);
    },
    getWXcode() {
      axios.get('/auth/weChatMPTicket', {}).then((res) => {
        const { noncestr, ticket, timestamp } = res.data.data||{}
        const data = {
          noncestr: noncestr,
          jsapi_ticket: ticket,
          timestamp: timestamp,
          url: window.location.href.split('#')[0],
        }
        const code = `jsapi_ticket=${data.jsapi_ticket}&noncestr=${data.noncestr}&timestamp=${data.timestamp}&url=${data.url}`
        const key = CryptoJS.SHA1(code).toString();
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx507a817966a379b5', // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.noncestr, // 必填，生成签名的随机串
          signature: key,// 必填，签名
          jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表
        });
        // eslint-disable-next-line no-undef
        wx.ready(function () {
          wx.updateAppMessageShareData({
            title: '天空文旅发展（常州）有限公司', // 分享标题
            desc: '新概念文旅产业方案提供商\n游乐·萌宠·潮玩·酒店', // 分享描述
            link: 'http://www.tikkon.cn/tikkon/cultural-tourism', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://inc.tikkon.cn/image/ruoyi/2024/10/17/cc10b21cab224f51bcba104cfb6f8e46.jpg', // 分享图标
            success: function () {
              console.log('设置成功')
              // 设置成功
            }
          })
        
        })
        // eslint-disable-next-line no-undef
        wx.error(function (res) {
          console.log(res, 'xxxxxxxxxxxxx')
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      })
    }
  },
  mounted() {
    this.getWXcode()
  },
}
</script>

<style lang="scss" scoped>
  .img{
    width: 100%;
    margin: 0%;
    position: relative;
    display: block;
    z-index: 1;
  }
  .click{
    width:100% ;
    position: relative;
    .tobtn{
        position: absolute;
        width: 200px;
        height: 100px;
        right: 0;
        bottom: 0;
      z-index: 9;
      }
    .btn_list{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      left: 0;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding-top: 60px;
      .btn{
        width: 50%;
        height:190px;
      }
    
    }
  }
</style>