import { createRouter, createWebHistory } from 'vue-router'
import Index from '../page/index'
import VaskiSituation from '../page/vaski-situation'
import VaskiCulture from '../page/vaski-culture'
import VaskiStructure from '../page/vaski-structure'
import VaskiMember from '../page/vaski-member'
import VaskiExperience from '../page/vaski-experience'
import VaskiCooperation from '../page/vaski-cooperation'
import BusinessResearch from '../page/business-research'
import BusinessScience from '../page/business-science'
import BusinessCity from '../page/business-city'
import BusinessService from '../page/business-service'
import BusinessTourism from '../page/business-tourism'
import BusinessGovernment from '../page/business-government'
import NewsCenter from '../page/news-center'
import NewsItem from '../page/news-item'
import liaisonIndex from '../page/liaison-index'
import Tikkon from '../page/tikkon'
import TikkonCity from '../page/tikkon-city'
import TikkonService from '../page/tikkon-service'
import TikkonBuild from '../page/tikkon-build'
import TikkonCulturalTourism from '../page/tikkon-cultural-tourism'
import TikkonResearch from '../page/tikkon-research'
import TikkonTechnology from '../page/tikkon-technology'
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Index },
    { path: '/vaski-situation', component: VaskiSituation },
    { path: '/vaski-culture', component: VaskiCulture },
    { path: '/vaski-structure', component: VaskiStructure },
    { path: '/vaski-member', component: VaskiMember },
    { path: '/vaski-experience', component: VaskiExperience },
    { path: '/vaski-cooperation', component: VaskiCooperation },
    { path: '/business-research', component: BusinessResearch },
    { path: '/business-science', component: BusinessScience },
    { path: '/business-city', component: BusinessCity },
    { path: '/business-service', component: BusinessService },
    { path: '/business-tourism', component: BusinessTourism },
    { path: '/business-government', component: BusinessGovernment },
    { path: '/news', component: NewsCenter },
    { path: '/news-item', component: NewsItem },
    { path: '/contact-information', component: liaisonIndex },
    { path: '/tikkon', component: Tikkon },
    { path: '/tikkon/city', component: TikkonCity },
    { path: '/tikkon/service', component: TikkonService },
    { path: '/tikkon/build', component: TikkonBuild },
    { path: '/tikkon/cultural-tourism', component: TikkonCulturalTourism },
    { path: '/tikkon/research', component: TikkonResearch },
    { path: '/tikkon/technology', component: TikkonTechnology },
    {
      path: '/:pathMatch(.*)*',
      component: Index
    },
  ]
})
export default router