<template>
    <div class="nullerror">
        <div class="error—value">
            <img class="nullerror-img" src="@/assets/images/none_img.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'NullErrorBar',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {

        }
    },
    methods: {
    },
    mounted() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.nullerror {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    box-sizing: border-box;

    .error—value {
        width: 1400px;
        padding: 150px 0;
        padding-bottom: 250px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        display: flex;

        .nullerror-img {
            // position: relative;
            margin: 0 auto;
            width: 80%;
        }

    }



}


@media (max-width: 767px) {
    .nullerror {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
        box-sizing: border-box;
        padding-bottom: 100px;

        .error—bg {
            width: 100%;
            display: flex;
            background-color: #f3f3f3;
            padding-bottom: 30px;
            box-sizing: border-box;
        }

        .error—value {
            width: 100%;
            padding-top: 50px;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            display: block;

            .nullerror-img {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            .error—text {
                height: 120px;
                width: 100%;
                text-align: center;

                .text {
                    font-size: 40px;
                    line-height: 50px;
                    margin-bottom: 30px;
                    color: #2a6e9f;
                }

                .message {
                    font-size: 20px;
                    font-weight: 300;
                    color: #2a6e9f;
                }


            }

            .right-page {
                width: 100%;
                z-index: 99;
                box-sizing: border-box;
                color: #fff;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                display: flex;
                padding: 0 20px;

                .icon {
                    border-radius: 10px;
                    overflow: hidden;
                    flex-shrink: 0;
                    width: 160px;
                    height: 65px;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                    }
                }

                .icon-f {
                    border-radius: 10px;
                    overflow: hidden;
                    flex-shrink: 0;
                    width: 150px;
                    height: 150px;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                    }
                }

            }

        }


    }

}
</style>