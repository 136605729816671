<template>
    <div class="news-list" v-loading="loading">
        <div class="news-item" v-for="item in newsList" :key="item" @click="topItme(item)">
            <div class="img">
                <img :src="item.cover" alt="">
            </div>
            <div class="value">
                <div class="name">{{ item.title }}</div>
                <div class="text">{{ item.date }}</div>
                <div class="text">
                    {{ item.newValue }}
                </div>
            </div>
        </div>

        <div class="pagination">
            <div class="btn btna" v-if="pageNum>1"  size="mini" @click="setPage(-1)"></div>
            <el-pagination background @change="pageChange" layout="pager" :total="total" v-model:page-size="pageSize"
                v-model:current-page="pageNum">
            </el-pagination>
            <div class="btn btnb" v-if="total/pageSize>=2&&Math.ceil(total/pageSize)!==pageNum"  @click="setPage(1)" size="mini"></div>
        </div>
    </div>

</template>

<script>
import axios from "../../http"
export default {
    name: 'NewsList',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            total: 0,
            pageNum: 1,
            newsList: [],
            loading: true,
            pageSize: 12
        }
    },
    methods: {
        setPage(e) {
            if (this.total / this.pageSize <= 1) {
                return
            }
            const index = this.pageNum + e
            if (index > 0 || index <= this.total) {
                this.pageChange(index)
            }
        },
        pageChange(e) {
            this.pageNum = e
            this.getList()
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        topItme(item) {
            this.$router.push({ path: '/news-item', query: { id: item.newsId } })
        },
        getList() {

            this.loading = true
            axios.get('/system/info/list', {
                params: { pageNum: this.pageNum, pageSize: this.pageSize }
            })
                .then(response => {
                    if (response.data.code === 200) {
                        const data = response.data.rows
                        this.newsList = data.map(item => {
                            item.newValue = ''
                            item.content = JSON.parse(item.content).map((e) => {
                                if (e.tagName === 'h2') {
                                    item.newValue = e.content
                                }
                                return e
                            })
                            const d = new Date(item.newsTime)
                            item.date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
                            if (item.characters) {
                                const use = []
                                item.characters.split(',').forEach(e => {
                                    const info = e.split(':')
                                    use.push({ name: info[0], value: info[1] })
                                })
                                item.characters = use
                            }
                            return item
                        })
                        this.total = response.data.total
                    }
                    this.loading = false
                })
                .catch(error => {
                    console.error(error); // 处理错误情况
                    this.loading = false
                });
        }
    },
    mounted() {
        this.getList()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.news-list {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    animation: example1 1s ease-out 0s backwards;

    @keyframes example1 {
        0% {
            left: -100%;
            opacity: 0;
        }

        100% {
            left: 0;
            opacity: 1;
        }
    }

    .pagination {
        margin: 0 auto;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;

        .btn {
            width: 15px;
            height: 30px;


        }

        .btna {
            background: url('../../assets/images/left.png') no-repeat;
            margin-right: 15px;
            background-position: 0 10px;
        }

        .btnb {
            background: url('../../assets/images/right.png') no-repeat;
            margin-right: 0;
            margin-left: 15px;
            background-position: 0 10px;
        }

        :deep(.el-pager li) {
            border-radius: 50%;
            background-color: #f3f3f300;

        }

        :deep(.el-pager .is-active) {
            background-color: #296ea2;

        }

        :deep(.btn-prev) {
            background-color: #fff;

            &:disabled {
                background-color: #fff;
            }
        }

        :deep(.btn-next) {
            background-color: #fff;

            &:disabled {
                background-color: #fff;
            }
        }
    }

    .news-item {
        width: 457px;
        height: 562px;
        background-color: #f3f3f3;
        flex-shrink: 0;
        margin-bottom: 50px;
        margin-right: 20px;
        transition: background-color 0.5s ease-in-out;

        :nth-child(3n) {
            margin-right: 0;
        }

        .img {
            width: 100%;
            height: 200px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .value {
            box-sizing: border-box;
            padding: 30px;

            .name {
                font-size: 24px;
                color: #7c7c7c;
                margin-bottom: 24px;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .text {
                font-size: 15px;
                color: #7c7c7c;
                margin-bottom: 30px;
                -webkit-line-clamp: 4;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &:hover {
            background-color: #296ea2;

            .img {
                width: 100%;
                height: 200px;
            }

            .value {
                box-sizing: border-box;
                padding: 30px;

                .name {
                    color: #fff;

                }

                .text {
                    color: #fff;

                }
            }
        }
    }


}


@media (max-width: 767px) {
    .news-list {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 20px;
        padding-top: 30px;
        padding-bottom: 80px;
        position: relative;

        .pagination {
            left: 50%;
            transform: translateX(-50%);

            :deep(.el-pager li) {
                border-radius: 50%;
                background-color: #f3f3f300;

            }

            :deep(.el-pager .is-active) {
                background-color: #296ea2;

            }

            :deep(.btn-prev) {
                background-color: #fff;

                &:disabled {
                    background-color: #fff;
                }
            }

            :deep(.btn-next) {
                background-color: #fff;

                &:disabled {
                    background-color: #fff;
                }
            }
        }

        .news-item {
            width: 170px;
            height: 200px;
            background-color: #f3f3f3;
            flex-shrink: 0;
            margin-bottom: 20px;
            margin-right: 5px;

            .img {
                width: 100%;
                height: 60px;
            }

            .value {
                box-sizing: border-box;
                padding: 10px;

                .name {
                    font-size: 14px;
                    color: #7c7c7c;
                    margin-bottom: 10px;
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .text {
                    font-size: 12px;
                    color: #7c7c7c;
                    margin-bottom: 10px;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:hover {
                background-color: #296ea2;

                .img {
                    width: 100%;
                    height: 200px;
                }

                .value {
                    box-sizing: border-box;
                    padding: 30px;

                    .name {
                        color: #fff;

                    }

                    .text {
                        color: #fff;

                    }
                }
            }
        }


    }


}
</style>