<template>
  <div class="vaski">
    <img class="vaski-img" src="@/assets/images/2thopbue.png" alt="" />
    <div class="vaski-bg">
      <div class="vaski-tap">
        <div class="vaski-left">
          <span class="text" @click="clickTab">新闻中心</span>
          <el-icon class="icon" color="#666666">
            <ArrowRight />
          </el-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  components: {},
  props: {
  },
  data() {
    return {
      left: 0,
    };
  },
  methods: {
    clickTab() {
      this.$router.push('/news');
    },
   
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.vaski {
  width: 100%;
  overflow: hidden;
  cursor: default;

  .vaski-img {
    width: 100%;
  }

  .vaski-bg {
    width: 100%;
    border-bottom: solid 2px #f3f3f3;

    .vaski-tap {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #f3f3f3;
      height: 80px;

      .vaski-left {
        font-size: 16px;
        display: flex;
        align-items: center;

        .text {
          color: #666;
        }

        .icon {
          margin: 0 10px;
        }
      }

  
    }
  }
}

@media (max-width: 767px) {
  .vaski {
    .vaski-img {
      width: 250%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    .vaski-bg {
      width: 100%;
      border-bottom: solid 2px #f3f3f3;

      .vaski-tap {
        width: 100%;
        margin: 0 auto;
        display: block;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #f3f3f3;

        .vaski-left {
          font-size: 16px;
          display: flex;
          margin-left: 20px;
          height: 80px;

          .text {
            color: #666;
          }

          .icon {
            margin: 0 10px;
          }
        }

       
      }
    }
  }
}
</style>
