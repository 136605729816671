<template>
  <div>
    <div>
      <div class="situation">
        <div class="situation—value">
          <div class="situation—text">
            <div class="text">
              <div>江苏控股集团（江苏）</div>
              <div>有限公司</div>
            </div>
            <div class="message">TIKKON HOLDINGS</div>
            <div class="message">GROUP CO, LTD.</div>
          </div>
        </div>
        <div class="situation—value">
          <div class="situation—text">
            <span class="span">
              2010年立于江苏常州，集团产业分布“传媒发展、信息技术、城市发展、城市服务、文旅发展、市收工程”六大事业板块，下转数十家不同领域专业主体，致力于建设:辐射仝国的业务渠道网络、国内领先的合作科研机构、技术领先的行业专家人才、填补空白的科技研发成果。
              集团管理团队200余人，
            </span>
            <span class="span">
              用工规模逾1000人，业务范围涵盖:投资管理、科技研发、人工智能、软件工程、智能装备、物业管理、环卫保洁、安全防卫、电梯服务、停车管理、文旅策划、文旅运营、广告制作、网络运营、酒店管理、市政工程、装饰工程、园林T程、弱电丁程等细分行业。
            </span>
            <span class="span">
              在高质量发展的经济建设中，集团不断科技探索，积极研发进口替代或填补空白类科技产品;在中华民族伟人复兴的历史进程中，集团弘扬文化自信，切实抵御意识形态渗透或西方文化入侵;在中国特色社会主义的伟大道路上，集团坚持党建引领，深入履行民营企业的社会责任和时代使命。
            </span>
            <span class="span">
              天空”立身之本:以高质量的科技、管理、服务类产品，切实提升客户体验与行业效率，有益社会，有益人民。
            </span>
          </div>
        </div>
      </div>
      <div class="img-page">
        <img class="img imgMax" src="@/assets/images/company_img.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SituaTion",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      height: 880,
      startX: 0,
      endStartX: 0,
      left: -100,
      leftb: -100,
      isStatus: true,
    };
  },
  methods: {
    isPc() {
      var userAgentInfo = navigator.userAgent;
      var agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var i = 0; i < agents.length; i++) {
        if (userAgentInfo.indexOf(agents[i]) > 0) {
          flag = false;
          break;
        }
      }
      this.isStatus = flag;
    },
    startSwipe(e) {
      this.startX = e.touches[0].clientX;
    },
    swipe(e) {
      this.endStartX = e.touches[0].clientX;
    },
    endSwipe() {
      const swiperlist = this.$refs.swiperlist;
      if (this.endStartX !== 0) {
        if (this.endStartX - this.startX <= -50) {
          swiperlist.next();
        }
        if (this.endStartX - this.startX >= 50) {
          swiperlist.prev();
        }
      }

      this.endStartX = 0;
      this.startX = 0;
    },
  },
  mounted() { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.situation {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  padding: 90px 0;
  padding-bottom: 0;

  .situation—value {
    flex: 6;

    .situation—text {
      height: 400px;

      .text {
        font-size: 39px;
        line-height: 55px;
        margin-bottom: 30px;
      }

      .message {
        font-size: 22px;
        line-height: 29px;
      }

      .span {
        font-size: 15px;
        display: inline-block;
        margin-bottom: 28px;
      }

      position: relative;
      animation: example1 1s ease-out 0s backwards;
    }

    @keyframes example1 {
      0% {
        left: -100%;
        opacity: 0;
      }

      100% {
        left: 0;
        opacity: 1;
      }
    }
  }

  .situation—value:first-child {
    flex: 4;
    margin-right: 20px;
  }
}

.img-page {
  width: 1400px;
  margin: 0 auto;
  margin-bottom: 146px;

  .img {
    width: 100%;
    height: 250px;
  }

  .imgMax {
    height: 525px;
  }

  position: relative;
  animation: example1 1s ease-out 0s backwards;
}

@media (max-width: 767px) {
  .situation {
    width: 100%;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
    padding: 30px 0;

    .situation—value {
      width: 100%;

      .situation—text {
        height: auto;
        margin-bottom: 20px;

        .text {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 10px;
          text-align: center;
        }

        .message {
          font-size: 12px;
          text-align: center;
        }

        .span {
          font-size: 14px;
          display: inline-block;
          margin-bottom: 30px;
          box-sizing: border-box;
          padding: 0 20px;
        }
      }
    }

    .situation—value:first-child {
      width: 100%;
      margin-right: 0;
    }
  }

  .img-page {
    width: 100%;

    .img {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  }
}
</style>
