<template>
    <div class="news-item" v-loading="loading">
        <div class="left">
            <div class="time">{{ from.time }}</div>
            <div class="date">{{ from.date }}</div>
        </div>
        <div class="right">
            <div class="news-name">{{ from.title }}</div>
            <div v-for="(item, index) in from.content" :key="item.newsId">
                <div v-if="index === 3 && from.characters" class="news-user">
                    <div class="user-title">出席人员</div>
                    <el-row :gutter="20">
                        <el-col :span="12" v-for="e in from.characters" :key="e.name">
                            <el-row :gutter="20">
                                <el-col class="text" :span="10">{{ e.name }}</el-col>
                                <el-col class="text" :span="10">{{ e.value }}</el-col>
                            </el-row>
                        </el-col>

                    </el-row>
                </div>
                <div v-if="item.tagName === 'p'" class="news-text">
                    {{ item.content }}
                </div>
                <div v-if="item.tagName === 'h1'" class="news-text-name">
                    {{ item.content }}
                </div>
                <div v-if="item.tagName === 'img'" class="news-img">
                    <img :src="item.src" alt="" />
                    <div v-if="from.content[index+1]&&from.content[index+1].tagName==='h6'" class="message">图:{{from.content[index+1].content}}</div>
                    <div v-else class="message"></div>

                </div>
            </div>
            <div class="no-btn" @click="noneHref">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
                <div>返回</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../../http"
export default {
    name: 'NewsList',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            from: {},
            loading: false
        }
    },
    methods: {
        noneHref() {
            window.history.back()
        },
        getValue() {
            const id = this.$route.query.id
            this.loading = true
            axios.get('/system/info/detail/' + id)
                .then(response => {
                    if (response.data.code === 200) {
                        let data = response.data.data
                        data.content = JSON.parse(data.content)
                        const d = new Date(data.newsTime)
                        data.date = `${d.getFullYear()}.${d.getMonth() + 1}`
                        data.time = d.getDate()
                        if (data.characters) {
                            const use = []
                            data.characters.split(',').forEach(e => {
                                const info = e.split(':')
                                use.push({ name: info[0], value: info[1] })
                            })
                            data.characters = use
                        }
                        this.from = data
                    }
                    this.loading = false
                })
                .catch(error => {
                    console.error(error); // 处理错误情况
                    this.loading = false
                });
        }
    },
    mounted() {
        this.getValue()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.news-item {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: flex-start;

    .left {
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        margin-right: 50px;
        color: #7c7c7c;
        position: relative;
        box-sizing: border-box;
        padding-top: 10px;
        background-color: #f3f3f3;
        border-radius: 20px;

        .time {
            font-size: 40px;
            line-height: 50px;
            width: 100%;
            text-align: center;
            font-weight: 600;
        }

        .date {
            font-size: 18px;
            width: 100%;
            text-align: center;
        }


    }

    .right {
        position: relative;

        .news-name {
            width: 780px;
            font-size: 20px;
            color: #286ea2;
            margin-bottom: 50px;
        }

        .news-text {
            width: 780px;
            font-size: 15px;
            color: #7d7d7d;
            margin-bottom: 30px;
        }
        .news-text-name {
            width: 780px;
            font-size: 18px;
            font-weight: 600;
            color: #000000e0;
            margin-bottom: 30px;
        }
        .news-img {
            width: 780px;

            img {
                width: 100%;
                box-shadow: 30px 30px 0px #e9f0f6;
            }

            .message {
                font-size: 15px;
                color: #7d7d7d;
                margin-top: 40px;
                margin-bottom: 30px;
                text-align: center;
            }
        }

        .news-user {
            width: 780px;
            background-color: #e9f0f6;
            box-sizing: border-box;
            padding: 0 60px;
            padding-top: 60px;
            padding-bottom: 40px;
            position: relative;
            margin-bottom: 50px;

            .user-title {
                position: absolute;
                color: #004c8b;
                left: 60px;
                font-size: 20px;
                top: -15px;
                font-weight: 600;
            }

            .text {
                font-size: 16px;
                color: #7d7d7d;
                margin-bottom: 10px
            }
        }

        .no-btn {
            width: 100px;
            height: 30px;
            color: #fff;
            background-color: #286ea2;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }

}


@media (max-width: 767px) {
    .news-item {
        width: 100%;
        margin: 0 auto;
        display: flex;
        box-sizing: border-box;
        justify-content: flex-start;
        padding: 50px 20px;

        .left {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            color: #7c7c7c;
            position: relative;
            box-sizing: border-box;
            padding-top: 10px;
            background-color: #f3f3f3;
            border-radius: 5px;
            position: absolute;

            .time {
                font-size: 20px;
                line-height: 20px;
                width: 100%;
                text-align: center;
                font-weight: 600;
            }

            .date {
                font-size: 12px;
                width: 100%;
                text-align: center;
            }


        }

        .right {
            position: relative;
            width: 100%;

            .news-name {
                width: 100%;
                font-size: 20px;
                color: #286ea2;
                margin-bottom: 50px;
                box-sizing: border-box;
                padding-left: 56px;

            }


            .news-text {
                width: 100%;
                font-size: 15px;
                color: #7d7d7d;
                margin-bottom: 30px;
            }

            .news-img {
                width: 100%;

                img {
                    width: 100%;
                    box-shadow: 10px 10px 0px #e9f0f6;
                }

                .message {
                    font-size: 15px;
                    color: #7d7d7d;
                    margin-top: 40px;
                    margin-bottom: 30px;
                    text-align: center;
                }
            }

            .news-user {
                width: 100%;
                background-color: #e9f0f6;
                box-sizing: border-box;
                padding: 0 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                position: relative;
                margin-bottom: 30px;

                .user-title {
                    position: absolute;
                    color: #004c8b;
                    left: 60px;
                    font-size: 20px;
                    top: -15px;
                    font-weight: 600;
                }

                .text {
                    font-size: 16px;
                    color: #7d7d7d;
                    margin-bottom: 10px
                }
            }

            .no-btn {
                width: 100px;
                height: 30px;
                color: #fff;
                background-color: #286ea2;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }

    }



}
</style>