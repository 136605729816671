<template>
  <HeaderTab :tabIndex="1"></HeaderTab>
  <div class="vaski">
    <TabInfo :tabIndex="4"/>
    <Experience />
    <Footer />
  </div>
</template>

<script>
import HeaderTab from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import Experience from '../../components/vaski/Experience.vue'
import TabInfo from '../../components/vaski/TabInfo.vue'
export default {
  name: 'VaskiDom',
  components: {
    Footer,
    Experience,
    HeaderTab,
    TabInfo
  },
  data() {
    return {
    }
  },
  methods: {
    
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.vaski {
  width: 100%;
  overflow: hidden;
  cursor: default;

}

</style>