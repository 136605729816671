<template>
    <div id="news-list" class="list" v-if="isStatus">
        <div class="list-info" :style="`left:${left}%`">
            <el-row :gutter="20">
                <el-col :span="12" :key="item">
                    <el-carousel ref="carousel" :height="`480px`" :autoplay="false" @change="imgChange"
                        indicator-position="none">
                        <el-carousel-item v-for="item in newsList" :key="item" @click="topItme(item)">
                            <div class="list-img">
                                <img :src="item.cover" alt="">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </el-col>
                <el-col :span="12">
                    <div class="right-bd">
                        <div :class="['right', index === textIndex ? 'right-h' : '']" v-for="(item, index) in newsList"
                            :key="item" v-on:mouseenter="getText(index)" @click="topItme(item)">
                            <div :class="['right-date']">
                                <div class="tiem">{{ item.time }}</div>
                                <div class="date">{{ item.date }}</div>
                            </div>
                            <div class="right-text">
                                <div class="name">
                                    {{ item.title }}
                                </div>
                                <div class="text" v-if="index === textIndex">
                                    {{ item.content[0].content }}
                                </div>
                                <div class="icon" v-else>
                                    <el-icon color="#7c7c7c">
                                        <ArrowRight />
                                    </el-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
    <div id="news-list" class="list" v-if="!isStatus">
        <div class="list-info">
            <el-carousel ref="carousel" :height="`200px`" :autoplay="false" @change="imgChange" @touchstart="startSwipe"
                @touchmove="swipe" @touchend="endSwipe" indicator-position="none">
                <el-carousel-item v-for="item in newsList" :key="item">
                    <div class="list-img">
                                <img :src="item.cover" alt="">
                            </div>
                </el-carousel-item>
            </el-carousel>
            <div class="right-bd">
                <div :class="['right', 'right-h']" v-for="(item, index) in newsList" :key="item"
                    v-on:mouseenter="getText(index)" @click="topItme(item)">
                    <div :class="['right-date']">
                        <div class="tiem">{{ item.time }}</div>
                        <div class="date">{{ item.date }}</div>
                    </div>
                    <div class="right-text">
                        <div class="name">
                            {{ item.title }}
                        </div>
                        <div class="text">
                            {{ item.content[0].content }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from '../http'
export default {
    name: 'NewsList',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            isStatus: true,
            textIndex: 0,
            left: -100,
            newsList: []
        }
    },
    methods: {
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            this.isStatus = flag;
        },
        getText(e) {
            this.textIndex = e
            this.$refs.carousel.setActiveItem(e)
        },
        imgChange(e) {
            this.textIndex = e
        },
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const carousel = this.$refs.carousel
            if (this.endStartX !== 0) {
                if (this.endStartX - this.startX <= -50) {
                    carousel.next()
                }
                if (this.endStartX - this.startX >= 50) {
                    carousel.prev()
                }
            }

            this.endStartX = 0
            this.startX = 0
        },
        topItme(item) {
            this.$router.push({ path: '/news-item', query: { id: item.newsId } })
        },
        getList() {
            axios.get('/system/info/list', {
                params: { pageNum: this.pageNum, pageSize: 12 }
            })
                .then(response => {
                    if (response.data.code === 200) {
                        const data = response.data.rows
                        console.log(data)
                        this.newsList = data.map(item => {
                            item.content = JSON.parse(item.content)
                            const d = new Date(item.newsTime)
                            item.date = `${d.getFullYear()}.${d.getMonth() + 1}`
                            item.time = d.getDate()
                            if (item.characters) {
                                const use = []
                                item.characters.split(',').forEach(e => {
                                    const info = e.split(':')
                                    use.push({ name: info[0], value: info[1] })
                                })
                                item.characters = use
                            }
                            return item
                        })
                    }
                })
                .catch(error => {
                    console.error(error); // 处理错误情况
                });
        }
    },
    mounted() {
        this.isPc()
        this.getList()
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // 组件进入可视区域
                    console.log('组件进入可视区域');
                    this.left = 50

                }
            });
        });
        // 获取需要监听的组件
        const target = document.getElementById('news-list');

        // 开始监听
        observer.observe(target);
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/*引用字库*/
@font-face {
    font-family: "MontserratMedium";
    src: url("../assets/font/Montserrat-Medium.otf");
}

.list {
    width: 100%;
    box-sizing: border-box;
    background-color: #e4e4e4;

    .list-info {
        width: 1400px;
        height: 570px;
        position: relative;
        transform: translateX(-50%);
        box-sizing: border-box;
        padding-top: 50px;
        transition: left 1s ease-in-out;
        overflow: hidden;

        .list-img {
            width: 100%;
            height: 480px;
            overflow: hidden;
            position: relative;

            img {
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .right-bd {
            width: 100%;
            height: 480px;
            overflow-x: auto;


        }

        .right-bd::-webkit-scrollbar {
            display: none;
        }

        .right {
            display: flex;
            box-sizing: border-box;
            margin-left: 50px;
            margin-bottom: 20px;
            border-bottom: solid 1px #7c7c7c;
            height: auto;
            cursor: default;

            .right-date {
                width: 100px;
                height: 100px;
                flex-shrink: 0;
                margin-right: 30px;
                color: #7c7c7c;
                position: relative;
                box-sizing: border-box;
                padding-top: 10px;

                .tiem {
                    font-size: 52px;
                    line-height: 60px;
                    width: 100%;
                    text-align: center;
                    font-family: MontserratMedium;

                }

                .date {
                    font-size: 15px;
                    width: 100%;
                    text-align: center;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 50px;
                    right: 0;
                    top: 20px;
                    background-color: #7c7c7c;

                }
            }

            .right-text {
                color: #7c7c7c;
                width: 500px;
                position: relative;

                .name {
                    font-size: 18px;
                    margin-bottom: 10px;
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-family: MontserratMedium;
                }

                .text {
                    font-size: 16px;
                    font-family: MontserratMedium;
                }

                .icon {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 30px;
                }
            }
        }

        .right-h {
            padding-bottom: 30px;

            .right-date {
                background-color: #286ea2;
                color: #fff;
                border-radius: 10px;
            }

            .right-text {
                .name {
                    font-size: 20px;
                    color: #286ea2;
                    -webkit-line-clamp: 2;
                    font-family: MontserratMedium;

                }

                .text {
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-family: MontserratMedium;
                }

                .icon {
                    position: absolute;
                    right: 0;
                    top: 50px;
                }
            }
        }
    }

}


@media (max-width: 767px) {
    .list {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        background-color: #f3f3f3;

        .list-info {
            width: 100%;
            height: auto;
            position: relative;
            transform: translateX(0);
            padding-top: 0;

            .list-img {
                width: 100%;
                height: 200px;
                overflow: hidden;
                position: relative;

                img {
                width: 100%;
                    height:auto;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .right-bd {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                padding: 20px 10px
            }

            .right {
                margin-left: 0;
                margin-bottom: 10px;
                height: auto;
                cursor: default;

                .right-date {
                    width: 60px;
                    height: 60px;

                    .tiem {
                        font-size: 30px;
                        line-height: 30px;
                        width: 100%;
                        text-align: center;
                        font-family: MontserratMedium;
                    }

                    .date {
                        font-size: 12px;
                        width: 100%;
                        text-align: center;
                        font-family: MontserratMedium;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 2px;
                        height: 20px;
                        right: 0;
                        top: 20px;
                        background-color: #7c7c7c;

                    }
                }

                .right-text {
                    color: #7c7c7c;

                    .name {
                        font-size: 12px;
                    }

                    .text {
                        font-size: 12px;
                        margin-bottom: 10px;
                        font-family: MontserratMedium;

                    }

                    .icon {
                        top: 30px;
                    }
                }
            }

            .right-h {
                .right-date {
                    color: #fff;

                    &::after {
                        content: '';
                        background-color: #7c7c7c00;
                    }
                }

                .right-text {
                    .name {
                        font-size: 14px;
                        color: #286ea2;
                        -webkit-line-clamp: 2;
                    }

                    .text {
                        font-size: 12px;
                        font-family: MontserratMedium;
                    }


                }
            }
        }

    }

}
</style>