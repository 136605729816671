
import axios from 'axios';

// 创建 axios 实例
const service = axios.create({
  // 生产环境
  baseURL:process.env.VUE_APP_BASE_URL +process.env.VUE_APP_BASE_API,
  // 本地
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000
});
export default service;
