<template>
  <HeaderTab :tabIndex="3"></HeaderTab>
  <div class="vaski">
    <TabBar />
    <Item />
    <Footer />
  </div>
</template>

<script>
import HeaderTab from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import Item from '../../components/news/Item.vue'
import TabBar from '../../components/news/TabBar.vue'
export default {
  name: 'NewsItem',
  components: {
    Footer,
    Item,
    HeaderTab,
    TabBar
  },
  data() {
    return {
    }
  },
  methods: {
    
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.vaski {
  width: 100%;
  overflow: hidden;
  cursor: default;

}

</style>