<template>
  <HeaderTab :tabIndex="2"></HeaderTab>
  <div class="vaski">
    <TabBar :tabIndex="5"/>
    <Government />
    <Footer />
  </div>
</template>

<script>
import HeaderTab from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
// import NullError from '../../components/NullError.vue'
import Government from '../../components/business/Government.vue'
import TabBar from '../../components/business/TabBar.vue'
export default {
  name: 'VaskiDom',
  components: {
    Footer,
    Government,
    HeaderTab,
    TabBar
  },
  data() {
    return {
    }
  },
  methods: {
    
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.vaski {
  width: 100%;
  overflow: hidden;
  cursor: default;

}

</style>