<template>
  <HeaderTab :tabIndex="0"></HeaderTab>
  <div>
    <Swiper/>
    <MerchantList/>
    <NewsList/>
    <IconList/>
    <Footer/>
  </div>
</template>

<script>
import HeaderTab from '../../components/Header.vue'
import Swiper from '../../components/Swiper.vue'
import MerchantList from '../../components/MerchantList.vue'
import NewsList from '../../components/NewsList.vue'
import IconList from '../../components/IconList.vue'
import Footer from '../../components/Footer.vue'
export default {
  name: 'App',
  components: {
    Swiper,
    MerchantList,
    NewsList,
    IconList,
    Footer,
    HeaderTab
  }
}
</script>

<style>

</style>