<template>
    <div class="research">
        <div class="culture—value">
            <div class="culture—text">
                <div class="text">
                    <div>游乐·萌宠·潮玩·酒店</div>
                </div>
                <div class="message">
                    天空文旅发展(常州)有限公司是一家在游乐园规划运营、媒体传播、广告营销及乡村振兴等多领域具有深厚实力的综合性企业。我们专注于为游客打造独特的文旅体验，为企业提供高效的市场推广方案，并为乡村注入新的活力。

                </div>
                <div class="message">
                    天空文旅秉持创新与娱乐并重的理念，打造天空乐园体系，围绕“泡泡总动员”设计并运营了一系列富有创意和趣味性的项目，让游乐园制造欢乐承载童梦。

                </div>
                <div class="message">
                    同时，天空文旅能够为客户提供全方位、多维度的宣传推广服务。凭借专业的策划团队和精准的营销策略，我们将客户的品牌故事和文化内涵有效地传递给目标受众，提升品牌影响力。

                </div>
                <div class="message">
                    天空文旅积极响应国家乡村振兴战略，致力于通过文化旅游的方式推动乡村经济发展。我们结合地方特色和资源，开发乡村旅游产品，提升乡村的知名度和吸引力，帮助农民实现增收致富。
                </div>
                <div class="message">
                    作为一家有责任感的企业，我们始终坚持“以人为本，服务至上”的理念，不断提升服务质量，为客户和社会创造更多价值。我们期待与更多合作伙伴携手共进，共同推动文化旅游产业的繁荣发展。
                </div>
                <!-- <div class="btn">文旅发展官网</div> -->
            </div>
            <div class="right-page">
                <el-carousel ref="swiper" :height="`${height}px`" :autoplay="false" :interval="6000"
                    @touchstart="startSwipe" @touchmove="swipe" @touchend="endSwipe">
                    <el-carousel-item v-for="item in imgList" :key="item">
                        <img class="right-img" :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>

            </div>
        </div>

    </div>
</template>

<script>
import img1 from '../../assets/images/tourism-0.png'
export default {
    name: 'ResearchBar',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 580,
            startX: 0,
            endStartX: 0,
            imgList: [
                img1
            ]
        }
    },
    methods: {
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiper = this.$refs.swiper
            if (this.endStartX - this.startX <= -50) {
                swiper.next()
            }
            if (this.endStartX - this.startX >= 50) {
                swiper.prev()
            }
        },
        getHeight() {
            if (this.isPc()) {
                this.height = 580
                // PC端逻辑
            } else {
                // 手机端逻辑
                this.height = 280
            }
        },
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            return flag;
        }
    },
    mounted() {
        this.getHeight()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.research {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    :deep(.el-carousel .el-carousel__indicators) {
        left: 100%;
        transform: translateX(-110%);
        display: flex;

        .el-carousel__button {
            background-color: #fff;
            opacity: 1;
            border-radius: 0;
            height: 4px;
            width: 60px;
        }

        .is-active .el-carousel__button {
            background-color: #409eff;
        }
    }
    .culture—value {
        width: 1450px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        display: flex;
        margin-top: 60px;
        margin-bottom: 60px;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 60px;
        background: url('../../assets/images/service-bg-icon.png') no-repeat;
        background-position: 0px 680px;
        background-size: 600px;
        background-color: #f3f3f3;

        .culture—text {
            flex: 1;
            box-sizing: border-box;
            padding-right: 60px;


            .btn {
                width: 120px;
                height: 40px;
                background-color: #296ea2;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                color: #fff;
                font-size: 15px;
                margin-top: 80px;
            }

            .text {
                font-size: 33px;
                line-height: 50px;
                margin-bottom: 50px;
                color: #2a6e9f;
                position: relative;

                &::after {
                    content: '';
                    width: 30px;
                    border-bottom: solid 2px #2a6e9f;
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                }
            }

            .message {
                font-size: 15px;
                line-height: 24px;
                font-weight: 500;
                color: #7c7c7c;
                margin-bottom: 20px;
            }


        }

        .right-page {
            z-index: 99;
            box-sizing: border-box;
            width: 850px;
            position: relative;
            top: 0;

            .right-img {
                width: 850px;
                height: 580px;
            }

        }

    }



}


@media (max-width: 767px) {
    .research {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
        box-sizing: border-box;
        padding-bottom: 10px;
        padding-bottom: 10px;


        .culture—value {
            width: 100%;
            padding: 0px;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            display: block;

            .culture—text {
                width: 100%;
                text-align: left;
                padding: 20px;
                .btn {
                 margin: 0 auto;
                }
                .text {
                    font-size: 28px;
                    line-height: 50px;
                    margin-bottom: 30px;
                    color: #2a6e9f;

                    &::after {
                        border-bottom: none;
                    }
                }

                .message {
                    font-size: 20px;
                    font-weight: 500;
                    text-indent: 2em;
                    line-height: 30px;
                    color: #2a6e9f;
                }


            }

            .right-page {
                z-index: 99;
                box-sizing: border-box;
                color: #fff;
                padding: 0;
                width: 100%;
                height: auto;
                top: 0px;

                .right-img {
                    width: 100%;
                    height: 280px;
                }



            }

        }


    }

}
</style>