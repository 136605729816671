<template>
    <div class="culture">

        <div class="culture—value">
            <img class="culture-img" src="@/assets/images/icon-list-img-0.png" alt="">

            <!-- <div class="culture—text">
                <div class="text">
                    <div>合作品牌</div>
                </div>
                <div class="message">Cooperative Brand</div>
            </div>
            <div class="right-page">
                <div class="icon" v-for="item in 24" :key="item">
                    <img src="@/assets/images/icon.png" alt="">
                </div>
            </div> -->
        </div>
        <div class="culture—bg">
            <img class="culture-img" src="@/assets/images/icon-list-img-1.png" alt="">
            <!-- <div class="culture—value">
                <div class="culture—text">
                    <div class="text">
                        <div>合作媒体</div>
                    </div>
                    <div class="message">Cooperative Media</div>
                </div>
                <div class="right-page">
                    <div class="icon" v-for="item in 5" :key="item">
                        <img src="@/assets/images/icons.jpg" alt="">
                    </div>
                </div>
            </div> -->
        </div>
        <div class="culture—value">
            <img class="culture-img" src="@/assets/images/icon-list-img-2.png" alt="">
            <!-- <div class="culture—text">
                <div class="text">
                    <div>合作院校</div>
                </div>
                <div class="message">Cooperative Colleges</div>
            </div>
            <div class="right-page">

                <div class="icon-f" v-for="item in 5" :key="item">
                    <img src="@/assets/images/icon-f.jpg" alt="">
                </div>
            </div> -->
        </div>
    </div>

</template>

<script>
export default {
    name: 'CooperaTion',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 880,
            startX: 0,
            endStartX: 0,
            left: -100,
            leftb: -100,
            isStatus: true
        }
    },
    methods: {
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            this.isStatus = flag;
        },
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiperlist = this.$refs.swiperlist
            if (this.endStartX !== 0) {
                if (this.endStartX - this.startX <= -50) {
                    swiperlist.next()
                }
                if (this.endStartX - this.startX >= 50) {
                    swiperlist.prev()
                }
            }

            this.endStartX = 0
            this.startX = 0
        },
    },
    mounted() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@keyframes example1 {
    0% {
        left: -100%;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

.culture {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    animation: example1 1s ease-out 0s backwards;
    position: relative;

    .culture-img {
        width: 1400px;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        padding: 50px 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .culture—bg {
        width: 100%;
        background-color: #f3f3f3;
        box-sizing: border-box;
    }

    .culture—value {
        width: 1400px;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;

        .culture—text {
            height: 200px;
            flex: 2;

            .text {
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 30px;
                color: #2a6e9f;
            }

            .message {
                font-size: 20px;
                font-weight: 300;
                color: #2a6e9f;
            }


        }

        .right-page {
            flex: 3;
            z-index: 99;
            box-sizing: border-box;
            color: #fff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .icon {
                border-radius: 10px;
                overflow: hidden;
                flex-shrink: 0;
                width: 200px;
                height: 80px;
                margin-bottom: 20px;

                img {
                    width: 100%;
                }
            }

            .icon-f {
                border-radius: 10px;
                overflow: hidden;
                flex-shrink: 0;
                width: 150px;
                height: 150px;
                margin-bottom: 20px;

                img {
                    width: 100%;
                }
            }

        }

    }


}


@media (max-width: 767px) {
    .culture {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    animation: example1 1s ease-out 0s backwards;
    position: relative;

    .culture-img {
        width: 100%;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        padding: 50px;
        left: 50%;
        transform: translateX(-50%);
    }

    .culture—bg {
        width: 100%;
        background-color: #f3f3f3;
        box-sizing: border-box;
    }

    .culture—value {
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;

        .culture—text {
            height: 200px;
            flex: 2;

            .text {
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 30px;
                color: #2a6e9f;
            }

            .message {
                font-size: 20px;
                font-weight: 300;
                color: #2a6e9f;
            }


        }

        .right-page {
            flex: 3;
            z-index: 99;
            box-sizing: border-box;
            color: #fff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .icon {
                border-radius: 10px;
                overflow: hidden;
                flex-shrink: 0;
                width: 200px;
                height: 80px;
                margin-bottom: 20px;

                img {
                    width: 100%;
                }
            }

            .icon-f {
                border-radius: 10px;
                overflow: hidden;
                flex-shrink: 0;
                width: 150px;
                height: 150px;
                margin-bottom: 20px;

                img {
                    width: 100%;
                }
            }

        }

    }


}


}
</style>