<template>
  <router-view />
</template>
<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-size: 100%;
}
body{
  margin: 0;
}

body {
  font-family: 'Source Han Sans CN', 'Source Han Sans TW', 'Source Han Sans KR', 'Source Han Sans', 'Noto Sans CJK SC', 'Noto Sans CJK TC', 'Noto Sans CJK HK', 'Noto Sans CJK', 'Source Han Sans SC', 'Source Han Sans TC', 'Source Han Sans HK', 'Microsoft YaHei', sans-serif;
}
</style>
